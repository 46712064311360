<i18n locale="th" lang="yaml" >
button.create_drone : "เพิ่ม Drone ใหม่"
drone.search.keyword.placeholder : "ค้นหาจาก Drone S/N"
</i18n>

<template>
	<div class="page-padding">

		<div class="page-actions">
			<a-button
				v-if="canCreateDrone"
				class="btn-page-action"
				type="primary"
				icon="plus"
				@click="clickCreateDrone">
				{{$t('button.create_drone')}}
			</a-button>
		</div>

		<DronesPerLotChart
			bordered
			style="margin-bottom:32px;"
			class="center-block"
			:container-width="350"
			:lot-id="lot.id"
			:lot-name="lot.lot_name" />

		<div class="drone-search">
			<a-input
				v-model="filterKeyword"
				style="width:250px"
				:placeholder="$t('drone.search.keyword.placeholder')"
				allow-clear
				@change="debounceSearchChange">
				<a-icon slot="suffix" type="search" />
			</a-input>
		</div>
		<DroneTable
			bordered
			:hide-lot="true"
			:hide-model="true"
			:show-pixhawk="true"
			:row-key="record => record.id"
			:data-source="droneDataList"
			:pagination="pagination"
			:loading="loading"/>

	</div>
</template>

<script>
import DronesPerLotChart from "@components/chart/DronesPerLotChart.vue"
import DroneTable from "@components/drone/DroneTable.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import _debounce from "lodash/debounce"
export default {
	components : {
		DronesPerLotChart,DroneTable
	} ,
	props : {
		model : {
			type : null,
			default : () => []
		} ,
		lot : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			droneDataList : [] ,
			dataBucket : [] ,
			pagination : {
				pageSize : 1000 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			},
			filterKeyword :"",
			loading : false,
		}
	} ,
	computed : {
		canCreateDrone() {
			return this.$authorize('create','drone')
		}
	} ,
	watch : {
		"lot.id" : {
			handler() {
				this.fetchData()
			}
		}
	} ,
	created() {
		this.debounceSearchChange = _debounce(this.handleSearchChange,300);
	},
	mounted() {
		this.fetchData()
	} ,
	methods : {
		clickCreateDrone() {
			this.$open({name:'admin/drone_create',query: {model : this.model.id,lot : this.lot.id}})
		} ,
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1;
			this.fetchData();
		} ,
		fetchData(toPage=null) {
			if (!this.lot.id) {
				this.droneDataList = []
				return
			}
			const filter = {
				keyword : this.filterKeyword,
				lotId : this.lot.id
			}
			this.loading = true;
			axios.get("/api/drone-lots/search-drone",{params : {filter}}).then((response)=>{
				this.droneDataList = response.data.data.droneInstances;
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
	}
}
</script>
<style lang="less" scoped>
.drone-search {
	margin-bottom : 16px;
}
</style>
